<script>
export default {
  props: ["menu"],
  data(){
    return{

    }
  },
  computed:{
    styleCalc(){
      return `top: ${45 * this.active}px`
    },
    active(){
      return this.$store.state.steps.item
    }
  },
  methods:{
    switchItems(e){
      this.$store.commit("updateItem", parseInt(e.target.dataset.number))
    }
  },
}
</script>

<template>
  <div id="accor_dian">
    <ul class="show-dropdown main-navbar" @click="switchItems">
      <li class="selector-active" :style="styleCalc">
        <div class="top left"></div>
        <div class="bottom right">
        </div>
      </li>
      <router-link :to="{name: item.name}" tag="li" :data-number="index" :class="{'active': active === index}"
                   v-for="(item, index) in menu" :key="index">
        <span>{{item.ti}}</span>
      </router-link>
    </ul>
  </div>
</template>

<style scoped lang="less">
#accor_dian {
  background-color: #358F6C;
  width: 200px;
  min-width: 200px;
  padding: 12px 0 12px 10px;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  box-sizing: border-box;
}
.main-navbar{
  position: relative;
}
#accor_dian ul li span{
  color: rgba(255,255,255,0.5);
  text-decoration: none;
  font-size: 16px;
  line-height: 45px;
  display: block;
  padding: 0 20px;
  transition-duration:0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
  pointer-events: none;
  user-select: none;
}
#accor_dian ul li:hover span{
  color: white;
}
#accor_dian ul li{
  cursor: pointer;
}
#accor_dian ul li:first-child{
  pointer-events: none;
}
#accor_dian>ul>li.active>span{
  color: #358F6C;
  background-color: transparent;
  transition: all 0.7s;
}
#accor_dian span:not(:only-child):after {
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 10%;
  font-size: 14px;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#accor_dian .active>a:not(:only-child):after {
  transform: rotate(90deg);
}
.selector-active{
  width: 100%;
  display:inline-block;
  position:absolute;
  height: 45px;
  top: 0;
  left: 0;
  transition-duration:0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.selector-active .top, .selector-active .bottom{
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  right: 0;
}
.selector-active .top{
  top: -25px;
}
.selector-active .bottom{
  bottom: -25px;
}
.selector-active .top:before, .selector-active .bottom:before{
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #358F6C;
}
.selector-active .top:before{
  left: -25px;
  top: -25px;
}
.selector-active .bottom:before{
  bottom: -25px;
  left: -25px;
}
</style>

<script>
import LeftNavigation from "@/components/LeftNavigation.vue";
export default {
  components: {LeftNavigation}
}
</script>

<template>
  <div class="vehicle">
    <LeftNavigation :menu="[
      {name: 'vehicleList', ti: '车辆列表'},
      {name: 'MainTenance', ti: '保养记录'},
      {name: 'position', ti: '车辆定位'},
    ]"></LeftNavigation>
    <section>
      <router-view></router-view>
    </section>
  </div>
</template>

<style scoped lang="less">
  .vehicle{
    display: flex;
    height: 100%;
    overflow: hidden;
  }
  section{
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 200px);
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
</style>
